import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import { Outlet, Link, useLocation } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { renderSelectField } from '../fragments/SelectField'
import renderTextField from '../fragments/textField'
import { renderDateRangeSelect } from '../fragments/DateRangePicker'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import { fetchPedidos, fetchGruposClientes, fetchClientes, saveContrato, fetchContrato } from '../../actions'
import Paper from '@mui/material/Paper';


import BreadCrumbs from './breadCrumb';

let Filter = props => {
  const navigation = useLocation();
  const subPath = navigation.pathname.split('/')[2];
  const { saveContrato, fetchPedidos, fetchClientes, handleSubmit, gruposClientes, clientes, fetchContrato, reset } = props

  useEffect(async () => {
    if (subPath && subPath.length > 10) {
      await fetchContrato(subPath)
    } else {
      await fetchContrato()
      reset()
    }
    //fetchGruposClientes()
  }, [
    subPath, fetchContrato
  ]);

  const onSubmit = values => {
    saveContrato(values)
  }

  return <>
  <BreadCrumbs/>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>

          <Grid item xs={12} sm={6}>
            <Field
              id="descricao"
              name="descricao"
              component={renderTextField}
              label="Descrição"
              required={true}
              />
          </Grid>

          <Grid item xs={12} sm={12}>
            <h4>Vigência</h4>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Field
              id="from"
              name="inicio"
              component={renderDateRangeSelect}
              label="De:"
              required={true}
              />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Field
              id="to"
              name="fim"
              component={renderDateRangeSelect}
              label="Até:"
              required={true}
              />
          </Grid>

          <Grid container item >
            <Button variant="contained" color="primary" type="submit">
              Salvar
            </Button>

            <Button variant="contained" color="error" component={Link} to="/print/pedido/relatorio"
              style={{ marginLeft: '1em' }}
              >
              Cancelar
            </Button>
          </Grid>

        </Grid>
      </form>
</>
}
const obterGruposMenu = grupos => {
  const options = []
  options.push(<MenuItem key={-1} value=""><em>Selecione</em></MenuItem>)
  grupos.forEach((g, i) => {
    options.push(<MenuItem key={i} value={g.id} >{g.desc}</MenuItem>)
  })
  return options
}

const obterClientesMenu = clientes => {
  const options = []
  options.push(<MenuItem key={-1} value=""><em>Selecione</em></MenuItem>)
  clientes.forEach((g, i) => {
    options.push(<MenuItem key={i} value={g.id} >{g.desc}</MenuItem>)
  })
  return options
}

const onGrupoChange = (id, fetchClientes) => {
  fetchClientes({ 'grupo': id })
}
const now = new Date()
Filter = reduxForm({
  form: 'reportFilterForm',
  enableReinitialize: true
})(Filter)




function mapStateToProps({ contrato, pedidos, gruposClientes, clientes }) {
  
  const initialValues = contrato.payload ? (contrato.payload) : ({ inicio: startOfMonth(now), fim: endOfMonth(now) })
  return { initialValues, contrato, pedidos, gruposClientes, clientes };
}

Filter = connect(mapStateToProps, { fetchContrato, saveContrato, fetchPedidos, fetchGruposClientes, fetchClientes })(Filter);

export default Filter;