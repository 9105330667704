import React from 'react';
import { Routes, Route, Link as RouterLink, useParams } from "react-router-dom";
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import Relatorio from './relatorio'
import RelatorioDetalhado from './relatorio/detalhado'
import Tool from './tool'

const Pedido = () => {
    const {id} = useParams();
 return <div>Pedido {id}</div>   
}

const Pedidos = () => {
    return <div>Pedidos</div>   
   }

const Index = () => {
    return <Routes>
        <Route path='/relatorio/detalhado' element={<RelatorioDetalhado/>} />
        <Route path='/relatorio/*' element={<Relatorio/>} />
        <Route path='/tool/*' element={<Tool/>} />
        <Route path=':id' element={<Pedido/>} />
        <Route path='*' element={<Pedidos/>}/>
    </Routes>
}

export function IconBreadcrumbs() {
  return (
      <Paper>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit" to="/" component={RouterLink} >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          SIG2
        </Link>
        <Link
          underline="none"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"  
          >
          <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Perfil
        </Link>
      </Breadcrumbs>
          </Paper>
  );
}

export default Index