import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Routes, Route } from "react-router-dom";
import { fetchPedidos, fetchGruposClientes, fetchClientes } from '../../../actions';
import Filter from './filter'
import OrderReportTable from './orderReportTable'

let Index = props => {
  const { pedidos, gruposClientes, clientes, fetchPedidos, fetchGruposClientes, fetchClientes } = props
  useEffect(async () => {
    fetchGruposClientes()
  }, [fetchGruposClientes]);

return <Routes>
        <Route path='/print' element={<OrderReportTable pedidos={pedidos} />} />
        <Route path='/*' element={<Filter grupos={gruposClientes} fetchClientes={fetchClientes} fetchPedidos={fetchPedidos} clientes={clientes}/>}>
          <Route index element={<OrderReportTable pedidos={pedidos} />} />
        </Route>
    </Routes>
}

function mapStateToProps({ pedidos, gruposClientes, clientes }) {
  return { pedidos, gruposClientes, clientes };
}

Index = connect(mapStateToProps, { fetchPedidos, fetchGruposClientes, fetchClientes })(Index);

export default Index