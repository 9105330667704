import { useEffect, useState} from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import qs from 'qs'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import SelectMany from './selectMany'
import Calendar from './calendar'
import Table from './table'



const Index = props => {

    const [cobranca, setCobranca] = useState({});
    const [grupos, setGrupos] = useState([]);
    const [selectedGrupos, setSelectedGrupos] = useState(null);

    const [clientes, setClientes] = useState([]);
    const [selectedClientes, setSelectedClientes] = useState([]);

    const [de, setDe] = useState(startOfMonth(Date.now()));
    const [ate, setAte] = useState(endOfMonth(Date.now()));
    const [faturamento, setFaturamento] = useState(null);

    useEffect(async () => {
        const {data} = await axios.get('/sig-ws/rest/faturamento');
        setGrupos(data.grupos)
        setClientes(data.clientes)
        setCobranca(data)

  }, [axios, setCobranca]);

  const onSelectedGruposChange = async grupos => {
    setSelectedGrupos(grupos)
    filterClientesByGrupo(grupos)
  }

  const filterClientesByGrupo = grupos => {
    if (grupos.length > 0) {
        const filteredClientes = cobranca.clientes.filter(cli => cli.grupo ? grupos.includes(cli.grupo.id) : false)
        setClientes(filteredClientes)
    } else {
        setClientes(cobranca.grupos)
    }
  }

  const onSelectedClienteChange = clientes => {
    setSelectedClientes(clientes)
  }

  const search = async () => {
    // console.log("search");
    // console.log(de);
    // console.log(ate);
    // console.log(selectedGrupos);
    // console.log(selectedClientes);
    const params = {groups: selectedGrupos, fromDt: de, toDt: ate, clients: selectedClientes}
    const config = { params, paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "repeat" })
      } };
    const {data} = await axios.get('/sig-ws/rest/faturamento/nfe/detalhado', config);
    setFaturamento(data)

  }
    return <div>
            <h1>NFe / Relatório</h1>
            <hr/>
            <SelectMany data={grupos} selected={selectedGrupos} onChange={onSelectedGruposChange} placeholder="Select"/>
            <hr/>
            <SelectMany data={clientes} selected={selectedClientes} onChange={onSelectedClienteChange} placeholder="Select"/>
            <hr/>
            De: <Calendar value={de} setValue={setDe}/> 
            Até: <Calendar value={ate} setValue={setAte}/>
            <hr/>

            <div className="card flex justify-content-center">
                <Button label="Submit" onClick={search} />
            </div>

            {faturamento && renderFaturamento(faturamento)}
        </div>

}

const renderFaturamento = faturamento => <div>
  <h5>{faturamento.docCounter} notas fiscais processadas</h5>
  <Table itens={faturamento.itens}/>
</div>

export default Index