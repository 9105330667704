//import _ from 'lodash';
import axios from 'axios';
import formatISO from 'date-fns/formatISO'
//import moment from 'moment';
//import { SubmissionError } from 'redux-form'
import { FETCH_PEDIDOS, FETCH_PEDIDOS_FAILURE, FETCH_PEDIDOS_SUCCESS } from './types';
import { handleError } from './errorHandler';

const BASE_URL = process.env.PUBLIC_URL;

export const fetchPedidos = params => async dispatch => {
    dispatch({ type: FETCH_PEDIDOS });
    const parsedParams = {...params}
    parsedParams.fromDt = formatISO(params.fromDt)
    parsedParams.toDt = formatISO(params.toDt)
    try {
        const config = { params: parsedParams };
        const res = await axios.get(`${BASE_URL}/sig-ws/rest/pedidos2`, config);
        dispatch({ type: FETCH_PEDIDOS_SUCCESS, payload: res.data });
    } catch (err) {
        handleError(dispatch, FETCH_PEDIDOS_FAILURE, err);
    }
}

export const updatePedidos = data => async dispatch => {
    dispatch({ type: FETCH_PEDIDOS });
    const parsedParams = {...data}
    parsedParams.fromDt = formatISO(data.fromDt)
    parsedParams.toDt = formatISO(data.toDt)
    try {
        await axios.post(`${BASE_URL}/sig-ws/rest/pedidos2`, parsedParams);
        // const res = 
        // dispatch({ type: FETCH_PEDIDOS_SUCCESS, payload: res.data });
    } catch (err) {
        console.log(err);
        handleError(dispatch, FETCH_PEDIDOS_FAILURE, err);
    }
}