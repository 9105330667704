import { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

export default function BasicDemo(props) {
    
    const {itens} = props

    const dt = useRef(null);

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
        </div>
    );
    
    return (
        <div className="card">
            <DataTable ref={dt} value={itens} header={header}>
                <Column field="descricao" header="Descrição"></Column>
                <Column field="documento" header="Pedido"></Column>
                <Column field="emissaoFormated" header="Emissão"></Column>
                <Column field="quantidade" header="Quantidade"></Column>
                <Column field="precoFormated" header="Preço médio"></Column>
                <Column field="totalFormated" header="Total"></Column>
                <Column field="grupo" header="Grupo"></Column>
                <Column field="cliente" header="Cliente"></Column>
            </DataTable>
        </div>
    );
}
         