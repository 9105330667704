import { Field, reduxForm } from 'redux-form'
import {Outlet, Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {renderSelectField} from '../../fragments/SelectField'
import {renderDateRangeSelect} from '../../fragments/DateRangePicker'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'


let Filter = props => {
  const { fetchPedidos, fetchClientes, handleSubmit, grupos, clientes } = props  
  const onSubmit = values => {
    fetchPedidos(values)
  }

return <div>
  <form onSubmit={handleSubmit(onSubmit)}>
<Grid container spacing={1}>

<Grid item xs={12} sm={4}>
<Field
id="group"
name="group"
component={renderSelectField}
label="Grupo:"
onChange={ (e, id) => {onGrupoChange(id, fetchClientes)}}
required={true}
>
{obterGruposMenu(grupos.payload)}
</Field>
</Grid>

<Grid item xs={12} sm={4}>
<Field
id="client"
name="client"
component={renderSelectField}
label="Cliente:"
>
{obterClientesMenu(clientes.payload)}
</Field>
</Grid>
<Grid item xs={12} sm={4}></Grid>

<Grid item xs={12} sm={4}>
<Field
id="from"
name="fromDt"
component={renderDateRangeSelect}
label="De:"
required={true}
/>
</Grid>

<Grid item xs={12} sm={4}>
<Field
id="to"
name="toDt"
component={renderDateRangeSelect}
label="Até:"
required={true}
/>
</Grid>

<Grid container item >
  <Button variant="contained" color="primary" type="submit">
                Processar
              </Button>

              <Button variant="contained" color="primary" component={Link} to="/print/pedido/relatorio"
                style={{marginLeft: '1em'}}
              >
                Imprimir
              </Button>
</Grid>

</Grid>
</form>
<Outlet />
</div>
}
const obterGruposMenu = grupos => {
  const options = []
  options.push(<MenuItem key={-1} value=""><em>Selecione</em></MenuItem>)
  grupos.forEach((g, i) => {
    options.push(<MenuItem key={i} value={g.id} >{g.desc}</MenuItem>)
  })
  return options
  }

  const obterClientesMenu = clientes => {
    const options = []
    options.push(<MenuItem key={-1} value=""><em>Selecione</em></MenuItem>)
    clientes.forEach((g, i) => {
      options.push(<MenuItem key={i} value={g.id} >{g.desc}</MenuItem>)
    })
    return options
  }

  const onGrupoChange = (id, fetchClientes) => {
    fetchClientes({'grupo': id})
  }
  const now = new Date()
  Filter = reduxForm({
    form: 'reportFilterForm',
    initialValues: {fromDt: startOfMonth(now), toDt: endOfMonth(now)}
  })(Filter)

  export default Filter