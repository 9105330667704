import React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

export const renderSelectField = ({ input, label, meta: { touched, error }, children, ...custom }) => (

  <FormControl fullWidth style={{ paddingTop: '1em' }} error={touched && error ? true : false} aria-describedby="name-error-text">
    <InputLabel>
      {label}
    </InputLabel>
    <Select
      displayEmpty={true}
      margin='none'
      {...input}
      {...custom}
      children={children} />
  </FormControl>
)