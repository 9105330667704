import {
    FETCH_CONTRATOS_DATA, FETCH_CONTRATOS_SUCCESS, FETCH_CONTRATOS_FAILURE,
} from '../actions/types';

const initialState = {
    isLoading: false,
    payload: [],
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case FETCH_CONTRATOS_DATA:
            return {
                ...state,
                isLoading: true
            };

        case FETCH_CONTRATOS_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                isLoading: false
            };

        case FETCH_CONTRATOS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
            
        default:
            return state;
    }
}

export default reducer;