import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import { withStyles } from '@mui/styles';
import { Grid, Button, Card, CardHeader, CardContent, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { login} from '../../actions';
import renderTextField from '../fragments/textField';
import Loading from '../loading';

const styles = theme => ({

});

let Signin = props => {
    const { handleSubmit, pristine, submitting, invalid, login } = props;
    if (submitting) {
        return <Loading/>
    }
    return (
        <Grid container justifyContent="center" spacing={3}>
            <Grid item sm={4}>
                <Card>
                    <CardHeader
                        avatar={
                            <AccountCircle style={{ fontSize: 36 }} />
                        }
                        title="ENTRAR"
                        subheader="Informe seu e-mail e senha:"
                    />

                    <form onSubmit={handleSubmit(values => login(values))} autoComplete="off">

                        <CardContent>

                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12}>
                                    <Field
                                        id="email"
                                        name="email"
                                        component={renderTextField}
                                        label="E-mail"
                                        autoComplete="email"
                                        required={true}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Field
                                        id="password"
                                        name="password"
                                        type="password"
                                        component={renderTextField}
                                        label="Senha"
                                        autoComplete="current-password"
                                        required={true}
                                    />
                                </Grid>

                            </Grid>

                        </CardContent>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography align="center" color="error">{login.error ? 'Erro: Verifique seus dados.' : null}</Typography>
                                </Grid>
                                <Grid item xs={3} sm={3}></Grid>
                                <Grid item xs={6} sm={6}>
                                    <Button fullWidth type="submit" disabled={pristine || submitting || invalid} variant="contained" color="primary">Entrar</Button>
                                </Grid>
                                <Grid item xs={3} sm={3}></Grid>
                            </Grid>
                        </CardContent>
                    </form>
                </Card>
            </Grid>
        </Grid>

    );
}

const validate = values => {
    const errors = {};

    if (!values.email) {
        errors.email = 'Informe o e-mail.';
    }

    if (!values.password) {
        errors.password = 'Informe a senha.';
    }

    return errors
}

Signin.propTypes = {
};

Signin = reduxForm({
    validate,
    form: 'signinForm'
})(withStyles(styles)(Signin));

const mapStateToProps = ({ login }) => {
    return { login };
}

export default connect(mapStateToProps, { login })(Signin);