import { Outlet, Link, useLocation } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';

export default props => {
    const navigation = useLocation();
    const subPath = navigation.pathname.split('/')[2];
    
    if (subPath && subPath.length > 10) {
    return <Breadcrumbs aria-label="breadcrumb">
    <Link underline="hover" color="inherit" to={'/contrato'}>
      Contratos
    </Link>
    <Typography color="text.primary">Editar</Typography>
  </Breadcrumbs>
    } else if (subPath && subPath === 'new') {
        return <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to={'/contrato'}>
          Contratos
        </Link>
        <Typography color="text.primary">Novo</Typography>
      </Breadcrumbs>
        }

        else {
                return <Breadcrumbs aria-label="breadcrumb">
                <Typography color="text.primary">Contratos</Typography>
              </Breadcrumbs>
        }

  }