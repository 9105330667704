import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import { Routes, Route } from "react-router-dom";
import { Grid, Button, Card, CardHeader, CardContent, Typography } from '@mui/material';
import { fetchPedidos, fetchGruposClientes, fetchClientes, updatePedidos } from '../../../actions';
import renderTextField from '../../fragments/textField';
import Filter from './filter'
import OrderReportTable from './orderReportTable'

let Index = props => {
  const { pedidos, gruposClientes, clientes, fetchPedidos, fetchGruposClientes, fetchClientes, updatePedidos } = props
  useEffect(async () => {
    fetchGruposClientes()
  }, [fetchGruposClientes]);

return <Routes>
        <Route path='/*' element={<Filter updatePedidos={updatePedidos} pedidos={pedidos} grupos={gruposClientes} fetchClientes={fetchClientes} fetchPedidos={fetchPedidos} clientes={clientes}/>}>
          <Route index element={<Tool pedidos={pedidos} />} />
        </Route>
    </Routes>
}

let Tool = props => {
  const {pedidos, handleSubmit} = props
  const {payload} = pedidos
  const itens = {}
  console.log(payload.length);
  payload.forEach(p => {
    p.pedidoItens.forEach(i => {
      const {preco, quantidade, produto, valorTotal} = i
      const {code, descricao} = produto
      //console.log(code + ' ' + descricao, + ' ' + quantidade + ' ' + preco);
      
      if (itens[code]) {
        itens[code].quantidade += quantidade
        itens[code].valorTotal += valorTotal
      } else {
        itens[code] = {descricao, quantidade, valorTotal}
      }

    })
  });
  
  return <div>
    {Object.keys(itens).map(i => {
      const {descricao, quantidade, valorTotal} = itens[i]
      return <div key={i}>{i} {descricao} vt{valorTotal} q {quantidade} {parseFloat(valorTotal/quantidade).toFixed(2)}</div>
    })}
  </div>
}

function mapStateToProps({ pedidos, gruposClientes, clientes }) {
  return { pedidos, gruposClientes, clientes };
}

Index = connect(mapStateToProps, { fetchPedidos, fetchGruposClientes, fetchClientes, updatePedidos })(Index);

export default Index