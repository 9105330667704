import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Outlet, Link, useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';

import Breadcrumbs from './breadCrumb'
import {dateFormat} from '../../utils/dateFormat2';


export default props => {
    const navigate = useNavigate()
    const {contratos} = props

    return <>
    <Breadcrumbs />
    <Grid container justifyContent="end">
          <Grid item>
          <Button startIcon={<AddIcon/>} component={Link} to="new">Novo contrato</Button>
          </Grid>
      </Grid>

    <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Grupo/Categoria</TableCell>
            <TableCell>Descrição</TableCell>
            <TableCell>Início</TableCell>
            <TableCell>Fim</TableCell>
            <TableCell>Ativo</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {contratos.payload.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              hover={true}
              onClick={() => navigate(row.id)}
            >
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell>{row.descricao}</TableCell>
              <TableCell>{dateFormat.format(row.inicio)}</TableCell>
              <TableCell>{dateFormat.format(row.fim)}</TableCell>
              <TableCell>Sim</TableCell>
            </TableRow>
          ))}
        </TableBody>


    </Table>
    </TableContainer>
    </>
}

const BreadNavsMenu = props => {
  return <Breadcrumbs aria-label="breadcrumb">
  <Link underline="hover" color="inherit" to={'/contrato'}>
  Contratos
  </Link>
</Breadcrumbs>
}