import {
    FETCH_NFES_DATA, FETCH_NFES_SUCCESS, FETCH_NFES_FAILURE,
} from '../actions/types';

const initialState = {
    payload: null,
    isLoading: false,
    error: null
}

export default function (state = initialState, action) {

    switch (action.type) {
        case FETCH_NFES_DATA:
            return {
                ...state,
                isLoading: true
            };

        case FETCH_NFES_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                isLoading: false
            };

        case FETCH_NFES_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        default:
            return state;
    }
}