import {
    FETCH_PRODUTO_DATA, FETCH_PRODUTO_SUCCESS, FETCH_PRODUTO_FAILURE,
    SAVE_PRODUTO_DATA, SAVE_PRODUTO_SUCCESS, SAVE_PRODUTO_FAILURE,
    DELETE_PRODUTO_DATA, DELETE_PRODUTO_SUCCESS, DELETE_PRODUTO_FAILURE
} from '../actions/types';

const initialState = {
    isLoading: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case FETCH_PRODUTO_DATA:
            return {
                ...state,
                isLoading: true
            };

        case FETCH_PRODUTO_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                isLoading: false
            };

        case FETCH_PRODUTO_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        case SAVE_PRODUTO_DATA:
            return {
                ...state,
                isLoading: true
            };

        case SAVE_PRODUTO_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                isLoading: false
            };

        case SAVE_PRODUTO_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        case DELETE_PRODUTO_DATA:
            return {
                ...state,
                isLoading: true
            };

        case DELETE_PRODUTO_SUCCESS:
            return {
                ...state,
                isLoading: false
            };

        case DELETE_PRODUTO_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        default:
            return state;
    }
}