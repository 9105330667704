import React from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, Outlet, Link as RouterLink } from "react-router-dom";
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import PersonIcon from '@mui/icons-material/Person';

import UserCard from '../cards/UserCard'



const styles = theme => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
    }
});

const Home = props => {
    const { classes } = props;
    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12}><IconBreadcrumbs/></Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>Profile
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}></Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export function IconBreadcrumbs() {
  return (
      <Paper>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit" to="/" component={RouterLink} >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          SIG2
        </Link>
        <Link
          underline="none"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"  
          >
          <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Perfil
        </Link>
      </Breadcrumbs>
          </Paper>
  );
}

  

Home.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);