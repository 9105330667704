import React from "react";
import { connect } from 'react-redux'
import { Navigate } from "react-router-dom";
import Signin from './Signin';

const Auth = props => {
  const { isLoading, payload } = props.auth
  return !isLoading && !!payload ? <Navigate to={'/'}/> : <Signin />;
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps)(Auth);