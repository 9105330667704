import { FETCH_PEDIDOS, FETCH_PEDIDOS_FAILURE, FETCH_PEDIDOS_SUCCESS } from '../actions/types';

const initialState = {
  isLoading: false,
  payload: []
}

export default function (state = initialState, action) {
  switch (action.type) {

    case FETCH_PEDIDOS:
      return {
        ...state,
        isLoading: true
      };

    case FETCH_PEDIDOS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isLoading: false
      };

    case FETCH_PEDIDOS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false
      };

    default:
      return state;

  }
}