import axios from 'axios';
import {
    FETCH_GRUPOS_CLIENTES_DATA, FETCH_GRUPOS_CLIENTES_SUCCESS, FETCH_GRUPOS_CLIENTES_FAILURE
} from './types';

const BASE_URL = process.env.PUBLIC_URL;

export const fetchGruposClientes = (params) => async dispatch => {
    dispatch({ type: FETCH_GRUPOS_CLIENTES_DATA });
    try {
        const config = { headers: { authorization: localStorage.getItem('token') }, params };
        const res = await axios.get(`${BASE_URL}/sig-ws/rest/grupos`, config);
        dispatch({ type: FETCH_GRUPOS_CLIENTES_SUCCESS, payload: res.data });
    } catch (err) {
        dispatch({ type: FETCH_GRUPOS_CLIENTES_FAILURE, error: err });
    }
};
