import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Loading from '../../loading'
import { listaClientes, filtraPedidos, calculaTotalItens } from '../../../controllers/pedidoController';

let OrderReportTable = props => {
  const { pedidos } = props
  if(pedidos.isLoading) return <Loading />
  if(pedidos.payload.length < 1) return <div>nenhum resultado.</div>
  return <>
  <h1>Paula e Castro</h1>
  <h5>Relatório pedidos</h5>
  {renderClientsTables(pedidos.payload)}
  </>
}

const brlFormat = new Intl.NumberFormat(`pt-BR`, {
  currency: `BRL`,
  style: 'currency',
});

const dateFormat = new Intl.DateTimeFormat('pt-BR', {
  year: 'numeric', month: 'numeric', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  hour12: false,
  timeZone: 'America/Sao_Paulo'
});

const renderClientsTables = pedidos => {

  const clientes = listaClientes(pedidos);

  return clientes.map(cliente => {
    const filtered = filtraPedidos(cliente, pedidos)
    return renderTable(cliente, filtered)
  })

}

const renderTable = (cliente, pedidos) => {
  const total = calculaTotalItens(pedidos)
  return (
    <TableContainer component={Paper} key={cliente}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{cliente}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>

              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Entrega</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pedidos.map(pedido => (
                    <TableRow key={pedido.id}>
                      <TableCell>{dateFormat.format(pedido.entrega)}</TableCell>
                      <TableCell colSpan={4}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell />
                              <TableCell>Quantidade</TableCell>
                              <TableCell>Valor</TableCell>
                              <TableCell>Total</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pedido.pedidoItens.map(item => (
                              <TableRow key={item.id}>
                                <TableCell>{item.produto.descricao}</TableCell>
                                <TableCell>{item.quantidade}</TableCell>
                                <TableCell>{brlFormat.format(item.preco)}</TableCell>
                                <TableCell>{brlFormat.format(item.valorTotal)}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

            </TableCell>

          </TableRow>
        </TableBody>
      </Table>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>TOTAIS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell />
            <TableCell colSpan={4}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Quantidade</TableCell>
                    <TableCell>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(total).map(item => {
                    const o = total[item]
                    return <TableRow key={item}>
                      <TableCell>{item}</TableCell>
                      <TableCell>{o.quantidade}</TableCell>
                      <TableCell>{brlFormat.format(o.total)}</TableCell>
                    </TableRow>
                    }
                  )}
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function mapStateToProps({ pedidos }) {
  return { pedidos };
}

export default connect(mapStateToProps)(OrderReportTable);