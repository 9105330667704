import {
    FETCH_PESSOA_DATA, FETCH_PESSOA_SUCCESS, FETCH_PESSOA_FAILURE,
    SAVE_PESSOA_DATA, SAVE_PESSOA_SUCCESS, SAVE_PESSOA_FAILURE,
    DELETE_PESSOA_DATA, DELETE_PESSOA_SUCCESS, DELETE_PESSOA_FAILURE
} from '../actions/types';

const initialState = {
    isLoading: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case FETCH_PESSOA_DATA:
            return {
                ...state,
                isLoading: true
            };

        case FETCH_PESSOA_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                isLoading: false
            };

        case FETCH_PESSOA_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        case SAVE_PESSOA_DATA:
            return {
                ...state,
                isLoading: true
            };

        case SAVE_PESSOA_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                isLoading: false
            };

        case SAVE_PESSOA_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        case DELETE_PESSOA_DATA:
            return {
                ...state,
                isLoading: true
            };

        case DELETE_PESSOA_SUCCESS:
            return {
                ...state,
                isLoading: false
            };

        case DELETE_PESSOA_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        default:
            return state;
    }
}