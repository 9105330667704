import axios from 'axios';
import {
    FETCH_CONTRATOS_DATA, FETCH_CONTRATOS_SUCCESS, FETCH_CONTRATOS_FAILURE,
    FETCH_CONTRATO_DATA, FETCH_CONTRATO_SUCCESS, FETCH_CONTRATO_FAILURE,
    SAVE_CONTRATO_DATA, SAVE_CONTRATO_SUCCESS, SAVE_CONTRATO_FAILURE
} from './types';

const BASE_URL = process.env.PUBLIC_URL;

export const fetchContratos = (params) => async dispatch => {
    dispatch({ type: FETCH_CONTRATOS_DATA });
    try {
        const config = { params };
        const res = await axios.get(`${BASE_URL}/sig-ws/rest/contratos`, config);
        dispatch({ type: FETCH_CONTRATOS_SUCCESS, payload: res.data });
    } catch (err) {
        dispatch({ type: FETCH_CONTRATOS_FAILURE, error: err });
    }
};

export const fetchContrato = id => async dispatch => {
    dispatch({ type: FETCH_CONTRATO_DATA });
    try {
        if(!id) {
            dispatch({ type: FETCH_CONTRATO_SUCCESS, payload: null });    
        } else {
            const res = await axios.get(`${BASE_URL}/sig-ws/rest/contratos/${id}`);
            dispatch({ type: FETCH_CONTRATO_SUCCESS, payload: res.data });
        }
    } catch (err) {
        dispatch({ type: FETCH_CONTRATO_FAILURE, error: err });
    }
};

export const saveContrato = data => async dispatch => {
    console.log("SAVE COJTRATO");
    dispatch({ type: SAVE_CONTRATO_DATA });
    try {
        //const config = { headers: { authorization: localStorage.getItem('token') }, params };
        const res = await axios.post(`${BASE_URL}/sig-ws/rest/contratos`, data);
        dispatch({ type: SAVE_CONTRATO_SUCCESS, payload: res.data });
    } catch (err) {
        dispatch({ type: SAVE_CONTRATO_FAILURE, error: err });
    }
};