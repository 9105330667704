import {
    FETCH_NFE_DATA, FETCH_NFE_SUCCESS, FETCH_NFE_FAILURE,
    SAVE_NFE_DATA, SAVE_NFE_SUCCESS, SAVE_NFE_FAILURE,
    DELETE_NFE_DATA, DELETE_NFE_SUCCESS, DELETE_NFE_FAILURE
} from '../actions/types';

const initialState = {
    payload: null,
    isLoading: false,
    error: null
}

export default function (state = initialState, action) {

    switch (action.type) {
        case FETCH_NFE_DATA:
            return {
                ...state,
                isLoading: true
            };

        case FETCH_NFE_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                isLoading: false
            };

        case FETCH_NFE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        case SAVE_NFE_DATA:
            return {
                ...state,
                isLoading: true
            };

        case SAVE_NFE_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                isLoading: false
            };

        case SAVE_NFE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        case DELETE_NFE_DATA:
            return {
                ...state,
                isLoading: true
            };

        case DELETE_NFE_SUCCESS:
            return {
                ...state,
                isLoading: false
            };

        case DELETE_NFE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        default:
            return state;
    }
}