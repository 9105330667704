const dateTimeFormat = new Intl.DateTimeFormat('pt-BR', {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: false,
    timeZone: 'America/Sao_Paulo'
  });

  const dateFormat = new Intl.DateTimeFormat('pt-BR', {
    year: 'numeric', month: 'numeric', day: 'numeric',
    timeZone: 'America/Sao_Paulo'
  });

export {dateFormat, dateTimeFormat};