import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import pessoaReducer from './pessoaReducer';
import produtoReducer from './produtoReducer';
import boletosReducer from './boletosReducer';
import boletoReducer from './boletoReducer';
import nfeReducer from './nfeReducer';
import nfesReducer from './nfesReducer';
import natOpsReducer from './natOpsReducer';
import pingReducer from './pingReducer';
import versionReducer from './versionReducer';
import labelsReducer from './labelsReducer';

import authReducer from './authReducer';
import settingsReducer from './settingsReducer';
import pedidosReducer from './pedidosReducer';
//import businessReducer from './businessReducer';
import gruposClientesReducer from './gruposClientesReducer';
import clientesReducer from './clientesReducer';
import contratoReducer from './contratoReducer';
import contratosReducer from './contratosReducer';

export default combineReducers({
  form: reduxForm,
  auth: authReducer,
  settings: settingsReducer,
  pedidos: pedidosReducer,
  //business: businessReducer,
  ping: pingReducer,
  version: versionReducer,
  labels: labelsReducer,
  pessoa: pessoaReducer,
  produto: produtoReducer,
  boletos: boletosReducer,
  boleto: boletoReducer,
  nfe: nfeReducer,
  nfes: nfesReducer,
  natOps: natOpsReducer,
  gruposClientes: gruposClientesReducer,
  clientes: clientesReducer,
  contrato: contratoReducer,
  contratos: contratosReducer
});
