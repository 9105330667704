import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import UserCard from '../cards/UserCard'

const styles = theme => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
    }
});

const Home = props => {
    const { classes } = props;
    return (
        <div className={classes.root}>.
            <Grid container>
            <Grid item xs={4}>
                        <UserCard />
            </Grid>
                </Grid>
        </div>
    );
}

export function IconBreadcrumbs() {
  return (
      <Paper>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="none"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit">
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          SIG2
        </Link>
      </Breadcrumbs>
          </Paper>
  );
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);