import React from 'react';
import TextField from '@mui/material/TextField';

const renderTextField = ({ input, label, classes, meta: { touched, error }, ...custom }) => {
    return <TextField
        fullWidth
        label={label}
        error={touched && error}
        helperText={error}
        {...input}
        {...custom}
    />
}

export default renderTextField