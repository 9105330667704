import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Routes, Route, Link, useParams } from "react-router-dom";

import { fetchContratos, fetchGruposClientes, fetchClientes } from '../../actions';
import Form from './form';
import List from './list';

var Index = props => {
    const {contratos, fetchContratos} = props
    useEffect(async () => {
        fetchContratos()
      }, [fetchContratos]);

    return <>
    <Routes>
        <Route path=':id' element={<Form/>} />
        <Route path='*' element={<List contratos={contratos} />}/>
    </Routes>
    </>
}

function mapStateToProps({ contratos, gruposClientes, clientes }) {
    return { contratos, gruposClientes, clientes };
  }
  
  Index = connect(mapStateToProps, { fetchContratos, fetchGruposClientes, fetchClientes })(Index);

export default Index