import { Routes, Route } from 'react-router-dom';

import Auth from './auth';
import PrivateRoute from './auth/PrivateRoute';
import Template from './template'

export default props => {
    return <Routes>
              <Route path="/auth" element={<Auth />} />
              <Route path="*" element={<PrivateRoute auth={props.auth}><Template logout={props.logout} /></PrivateRoute>} />
          </Routes>
}