import React, { useState } from "react";
import { MultiSelect } from 'primereact/multiselect';

export default function BasicDemo(props) {

    const {data, selected, onChange, placeholder} = props

    return (
        <div className="card flex justify-content-center">
            <MultiSelect value={selected} onChange={(e) => onChange(e.value)} options={data} optionLabel="desc"  optionValue="id"
                maxSelectedLabels={3} className="w-full md:w-20rem" placeholder={placeholder} />
        </div>
    );
}