import {
  LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE,
  LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAILURE
} from '../actions/types';

const initialState = {
  isLoading: false
}

export default function (state = initialState, action) {
  switch (action.type) {

    case LOGIN:
      return {
        isLoading: true
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isLoading: false
      };

    case LOGIN_FAILURE:
      return {
        isLoading: false,
        error: action.error,
      };
      
      case LOGOUT:
        return {
          isLoading: true
        };
  
      case LOGOUT_SUCCESS:
        return {
          isLoading: false
        };
  
      case LOGOUT_FAILURE:
        return {
          ...state,
          error: action.error,
          isLoading: false
        };

    default:
      return state;

  }
}