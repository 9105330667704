import React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

export const renderDateRangeSelect = ({ input, label, meta: { touched, error }, children, ...custom }) => {
  return (
  <FormControl fullWidth error={touched && error ? true : false} aria-describedby="name-error-text">
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={ptBrLocale}
    >
      <div>

    <DatePicker
        mask='__/__/____'
        label={label}
        value={input.value}
        onChange={input.onChange}
        
        error={false}
        renderInput={(params) => <TextField fullWidth {...params} />}
        />
          </div>
  </LocalizationProvider>
  </FormControl>
)}

/*

export const renderSelectField = ({ input, label, meta: { touched, error }, children, ...custom }) => (

  <FormControl fullWidth style={{ paddingTop: '1em' }} error={touched && error ? true : false} aria-describedby="name-error-text">
    <InputLabel>
      {label}
    </InputLabel>
    <Select
      displayEmpty={true}
      margin='none'
      children={children} />
  </FormControl>
)
*/