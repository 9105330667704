export const listaClientes = pedidos => {
  const clientes = []
  pedidos.forEach( o => {
    const nomeCliente = o.cliente.nome
    if (clientes.includes(nomeCliente)) return
    clientes.push(nomeCliente)
  })
  return clientes
}

export const filtraPedidos = (cliente, pedidos) => {
  return pedidos.filter(pedido => {
    return pedido.cliente.nome === cliente
  })
}

export const calculaTotalItens = (pedidos) => {
  const total = {}
  pedidos.forEach(p => {
    p.pedidoItens.forEach(i => {
      if (total[i.produto.descricao]) {
        const o = total[i.produto.descricao]
         o.total += Number(i.valorTotal)
         o.quantidade += Number(i.quantidade)
      } else {
        const o = {total: Number(i.valorTotal), quantidade : Number(i.quantidade)}
        total[i.produto.descricao] = o
      }
    })
  })
  return total;
}