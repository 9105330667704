import { FETCH_SETTINGS_DATA, FETCH_SETTINGS_FAILURE, FETCH_SETTINGS_SUCCESS } from '../actions/types';

const initialState = {
  isLoading: false
}

export default function (state = initialState, action) {
  switch (action.type) {

    case FETCH_SETTINGS_DATA:
      return {
        ...state,
        isLoading: true
      };

    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isLoading: false
      };

    case FETCH_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false
      };

    default:
      return state;

  }
}